export enum LoanPurposeActivity {
  BUYING = 'Buying',
  BUYING_A_CAR = 'BuyingACar',
  BUYING_AND_FINISHING = 'BuyingAndFinishing',
  BUYING_AND_RENOVATING = 'BuyingAndRenovating',
  BUILDING = 'Building',
  RENOVATING = 'Renovating',
  ADAPTATION = 'Adaptation',
  REFINANCING = 'Refinancing',
  CONSOLIDATION = 'Consolidation',
  OTHER = 'Other',
  BUSINESS_LOAN = 'BusinessLoan',
  BUSINESS_ACCOUNT_LIMIT = 'BusinessAccountLimit',
  BUSINESS_LOAN_CONSOLIDATION = 'BusinessLoanConsolidation',
  BUSINESS_LOAN_WITH_COLLATERAL = 'BusinessLoanWithCollateral',
  NON_BANK_PRODUCTS = 'NonBankProducts',
  LEASING = 'Leasing',
  FACTORING = 'Factoring',
  EU_FUNDING = 'EUFunding',
}

export enum LoanPurposeHow {
  WITH_DEVELOPER = 'WithDeveloper',
  ON_YOUR_OWN = 'OnYourOwn',
}

export enum LoanPurposeWhat {
  HOUSE = 'House',
  HOLIDAY_HOME = 'HolidayHome',
  APARTMENT = 'Apartment',
  PLOT = 'Plot',
  OFFICE = 'Office',
  BUILDING_PLOT = 'BuildingPlot',
  BUILDING_PLOT_AND_CONSTRUCTION = 'BuildingPlotAndConstruction',
  BUILDING_PLOT_AND_HOUSE = 'BuildingPlotAndHouse',
  RECREATION_PLOT = 'RecreationPlot',
  AGRICULTURAL_PLOT = 'AgriculturalPlot',
}

export enum CustomerCommitmentLevelType {
  BROWSE_OFFERS = 'browse_offers',
  VISITS_REAL_ESTATES = 'visits_real_estates',
  HAS_SELECTED_PROPERTY = 'has_selected_property',
  HAS_LOAN_OFFER = 'has_loan_offer',
}

export enum AttributeTimeRange {
  IMMEDIATELY = 'immediately',
  UP_TO_A_MONTH = 'up_to_a_month',
  UP_TO_3_MONTHS = 'up_to3_months',
  UP_TO_1_YEAR = 'up_to1_year',
}